import React, { useEffect, useRef, useState, useTransition, useMemo } from "react";
import axios from "axios"
import { DndContext, useDraggable, useSensor, useSensors, MouseSensor, KeyboardSensor, PointerSensor, closestCenter, TouchSensor } from "@dnd-kit/core";

import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import './DraggableTable.css';
import './DraggableTableDark.css';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import PriceDifference from "../../priceDifference/priceDifference";
import { border, useToast } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { IoCopyOutline } from "react-icons/io5";
import { getOzonPorductList, handleClickRow, reorderOzonColumns, reorderOzonProducts, updateCompany, updateCurrentPage } from "../../../../redux/reducer/ozonGetProductLIst/action/action";
import sockets from "../../../../socket/socket";
import { Tooltip } from '@chakra-ui/react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { notification } from "antd";
import { Button, Popover } from "antd";
import { FixedSizeList } from 'react-window';
import { color } from "framer-motion";



const socket = sockets;
// Создаем класс для кастомного PointerSensor
class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false;
        }

        return true;
      },
    },
  ];
}

// Проверяем, является ли элемент интерактивным
function isInteractiveElement(element) {
  const interactiveElements = ["button", "input", "textarea", "select", "option"];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}
const DraggableColumn = ({ children, id, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = useMemo(() => ({
    transform: CSS.Transform.toString(transform),
    transition,
  }), [transform, transition]);

  return (
    <th ref={setNodeRef} style={style} {...attributes} {...listeners} className={index === 0 ? 'sticky' : ''}>
      {children}
    </th>
  );
};

const DraggableRow = ({ id, row, columns }) => {
  const dispatch = useDispatch()
  const getClcik = useSelector(state => state.getOzonisListReducer.ozon.isClick);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled: getClcik });
  const toast = useToast();
  const [comments, setComments] = useState(row.comments || ''); // Инициализация с данными из row
  const [isSaving, setIsSaving] = useState(false); // Индикатор сохранения
  const saveTimeoutRef = useRef(null); // Ссылка для хранения таймера
  const getDarkModoe = useSelector(darkModes => darkModes)
  const style = useMemo(() => ({
    transform: CSS.Transform.toString(transform),
    transition,
  }), [transform, transition]);
  const handleCopy = async (text) => {
    if (!text) {
      console.error("No text to copy");
      return;
    }
    console.log("Copy initiated for:", text);
    try {
      setTimeout(async () => {
        await navigator.clipboard.writeText(text);
        console.log('Text copied:', text);
        toast({
          position: "top",
          title: 'Текст скопирован.',
          description: "Текст скопирован.",
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }, 0); // Задержка для выполнения
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };
  const getColor = (percentage) => {
    if (percentage > 0) {
      return `rgba(72, 187, 120, ${percentage / 100})`;
    } else if (percentage < 0) {
      return `rgba(235, 87, 87, ${Math.abs(percentage) / 100})`;
    }
    return `rgba(253,229,236)`;
  };

  const textareaStyle = getDarkModoe.ToggleDarkModeReducer.chekced ? {

    width: "100%",
    minHeight: "500px",
    maxHeight: "auto",
    resize: "none", // Запрет ручного изменения размеров
    opacity: isSaving ? 0.5 : 1,
    backgroundColor: "#21262E",
    color: "white",



  } : {
    width: "100%",
    minHeight: "500px",
    maxHeight: "auto",
    resize: "none", // Запрет ручного изменения размеров
    opacity: isSaving ? 0.5 : 1,



  }


  useEffect(() => {
    const handleUpdateComment = ({ id, comments, commentType, date }) => {
      if (row.id === id) {
        setComments((prevComments) => ({
          ...prevComments,
          [commentType]: {
            ...prevComments[commentType], // Сохраняем остальные свойства
            value: comments, // Обновляем только value
            date, // Обновляем дату, если нужно
          },
        }));
      }

      // Диспатчим обновленные данные
      dispatch({
        type: "UPDATE_OZON_COMMENT",
        payload: { id, comments, commentType, date },
      });
    };

    // Подписка на событие от сокета
    socket.on('updateComment', handleUpdateComment);

    // Отписка при размонтировании
    return () => {
      socket.off('updateComment', handleUpdateComment);
    };
  }, [dispatch, row.id, socket]);


  // / Отправка комментария на сервер
  const saveComment = async (value, item, commentType) => {
    setIsSaving(true);
    const date = new Date().toISOString(); // Генерируем текущую дату
    await axios.put(`/api/commentsOzon/${id}`, {
      commentType,
      value,
      date,
    });
    try {
      socket.emit('updateComment', {
        id: item.id,
        comments: value,
        commentType, // Передаем тип комментария
        date: new Date(),
      });
    } catch (error) {
      console.error('Ошибка при сохранении комментария:', error);
    } finally {
      setIsSaving(false);
    }
  };


  // Обработка изменения текста с задержкой
  const handleChange = (e, item, commentType) => {
    const value = e.target.value;
    setComments((prevComments) => ({
      ...prevComments,
      [commentType]: { value, date: new Date() }, // Обновляем только нужный тип
    }));

    // Отправляем обновленные данные на сервер
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current);
    saveTimeoutRef.current = setTimeout(() => {
      saveComment(value, item, commentType);
    }, 1000);
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners}

    >

      {
        columns.map((col, index) => {
          return (
            <td key={col.id} className={index === 0 ? 'sticky' : ''}>
              {
                (() => {
                  if (col.id === "name") {
                    return (
                      <div>
                        <Image
                          src={row.images.length > 0 ? row.images[0] : "https://via.placeholder.com/150"}
                          fallbackSrc='https://via.placeholder.com/150'
                          boxSize='50px'
                          loading="lazy"
                        />
                        <div>
                          <p>{row.offer_id}</p>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IoCopyOutline
                              onMouseDown={(e) => {
                                e.stopPropagation(); // Останавливаем распространение события
                                handleCopy(row.sku);
                              }}
                              style={{ cursor: 'pointer', fontSize: '10px', marginRight: '5px' }}
                            />
                            <a href={`https://www.ozon.ru/product/${row.sku}`} target="_blank" rel="noopener noreferrer">
                              {row.sku}
                            </a>
                          </div>
                          <p>
                            <Tooltip label={row.name} aria-label='A tooltip'>
                              {row.name}
                            </Tooltip>

                          </p>
                        </div>

                      </div>
                    )
                  } else if (col.id === "price") {
                    const formatter = new Intl.NumberFormat('ru-RU', {
                      style: 'currency',
                      currency: 'RUB',
                    });
                    const formattedPrice = formatter.format(row.price);
                    return formattedPrice
                  } else if (col.id === "stock") {
                    return row.stocks.present
                  } else if (col.id === "id1") {
                    return <div style={{ backgroundColor: getColor(row.weeks.week1.totalPercentage), height: "auto" }} className="totalDiv">
                      {row.weeks.week1.totalPercentage}
                      <PriceDifference currentPrice={row.weeks.week1.totalPercentage} previousPrice={row.weeks.week1.totalPercentage} />
                    </div>
                  } else if (col.id === "id2") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week2.totalPercentage) }} className="totalDiv">
                        {row.weeks.week2.totalPercentage}
                        <PriceDifference currentPrice={row.weeks.week2.totalPercentage} previousPrice={row.weeks.week1.totalPercentage} />
                      </div>

                    )
                  } else if (col.id === "id3") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week3.totalPercentage) }} className="totalDiv">
                        {row.weeks.week3.totalPercentage}
                        <PriceDifference currentPrice={row.weeks.week3.totalPercentage} previousPrice={row.weeks.week2.totalPercentage} />
                      </div>
                    )
                  } else if (col.id === "id4") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week4.totalPercentage) }} className="totalDiv">
                        {row.weeks.week4.totalPercentage}
                        <PriceDifference currentPrice={row.weeks.week4.totalPercentage} previousPrice={row.weeks.week3.totalPercentage} />
                      </div>
                    )
                  } else if (col.id === "id5") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week5.totalPercentage) }} className="totalDiv">
                        {row.weeks.week5.totalPercentage}
                        <PriceDifference currentPrice={row.weeks.week5.totalPercentage} previousPrice={row.weeks.week4.totalPercentage} />
                      </div>
                    )
                  } else if (col.id === "id6") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week6.totalPercentage) }} className="totalDiv">
                        {row.weeks.week6.totalPercentage}
                        <PriceDifference currentPrice={row.weeks.week6.totalPercentage} previousPrice={row.weeks.week5.totalPercentage} />
                      </div>
                    )
                  } else if (col.id === "id7") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week7.totalPercentage) }} className="totalDiv">
                        {row.weeks.week7.totalPercentage}
                        <PriceDifference currentPrice={row.weeks.week7.totalPercentage} previousPrice={row.weeks.week6.totalPercentage} />
                      </div>
                    )
                  } else if (col.id === "id8") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week8.totalPercentage) }} className="totalDiv">
                        {row.weeks.week8.totalPercentage}
                        <PriceDifference currentPrice={row.weeks.week8.totalPercentage} previousPrice={row.weeks.week7.totalPercentage} />
                      </div>
                    )
                  }

                  // days

                  else if (col.id === "id1id") {

                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week8.day1.total) }} className="totalDiv">
                        {row.weeks.week8.day1.total}
                        <PriceDifference currentPrice={row.weeks.week8.day1.total} previousPrice={row.weeks.week8.day1.total} />
                      </div>
                    )
                  } else if (col.id === "id2id") {
                    return (
                      <div style={{ backgroundColor: getColor(row.weeks.week8.day2.total) }} className="totalDiv">
                        {row.weeks.week8.day2.total}
                        <PriceDifference currentPrice={row.weeks.week8.day2.total} previousPrice={row.weeks.week8.day1.total} />
                      </div>
                    )

                  } else if (col.id === "id3id") {
                    return <div style={{ backgroundColor: getColor(row.weeks.week8.day3.total) }} className="totalDiv">
                      {row.weeks.week8.day3.total}
                      <PriceDifference currentPrice={row.weeks.week8.day3.total} previousPrice={row.weeks.week8.day2.total} />
                    </div>
                  } else if (col.id === "id4id") {
                    return <div style={{ backgroundColor: getColor(row.weeks.week8.day4.total) }} className="totalDiv">
                      {row.weeks.week8.day4.total}
                      <PriceDifference currentPrice={row.weeks.week8.day4.total} previousPrice={row.weeks.week8.day3.total} />
                    </div>
                  } else if (col.id === "id5id") {
                    return <div style={{ backgroundColor: getColor(row.weeks.week8.day5.total) }} className="totalDiv">
                      {row.weeks.week8.day5.total}
                      <PriceDifference currentPrice={row.weeks.week8.day5.total} previousPrice={row.weeks.week8.day4.total} />
                    </div>
                  } else if (col.id === "id6id") {
                    return <div style={{ backgroundColor: getColor(row.weeks.week8.day6.total) }} className="totalDiv">
                      {row.weeks.week8.day6.total}
                      <PriceDifference currentPrice={row.weeks.week8.day6.total} previousPrice={row.weeks.week8.day5.total} />
                    </div>
                  } else if (col.id === "id7id") {

                    return <div style={{ backgroundColor: getColor(row.weeks.week8.day7.total) }} className="totalDiv">
                      {row.weeks.week8.day7.total}
                      <PriceDifference currentPrice={row.weeks.week8.day7.total} previousPrice={row.weeks.week8.day6.total} />
                    </div>
                  } else if (col.id === "Ads") {
                    return (
                      <div className="totalDiv" style={{ backgroundColor: getColor(row.ads) }}>
                        {row.ads}
                      </div>
                    )
                  } else if (col.id === "idc") {
                    return (
                      <div className={row.idc_grade}>
                        {row.idc}
                      </div>
                    )
                  } else if (col.id === "turnover") {


                    return (
                      <div className={row.turnover_grade}>
                        {row.turnover}
                      </div>
                    )
                  } else if (col.id === "strategy") {
                    return <div className="commentCont" style={{ opacity: isSaving ? 0.5 : 1 }}>
                      <Popover

                        className="myPopover"
                        overlayClassName="myPopover"
                        color={getDarkModoe.ToggleDarkModeReducer.chekced ? "#21262E" : "white"}
                        content={
                          <div
                            className="commentCont"
                            style={{
                              width: "100mm", // Пример ширины A4
                              minWidth: "100px",
                              borderRadius: "4px",
                              overflow: "hidden",
                              border: "none",
                            }}
                          >
                            <textarea
                              className="commentOzon"
                              placeholder="Введите ..."
                              value={comments.strategy.value}
                              onChange={(e) => handleChange(e, row, "strategy")}
                              style={textareaStyle}

                            />
                          </div>
                        }
                        title=""


                      >
                        <p>{comments.strategy.value}</p>
                        <p>{
                          comments.strategy.date && comments.strategy.value ?
                            moment(comments.strategy.date).format("ll")
                            :
                            ""
                        }</p>
                      </Popover>
                    </div>
                  } else if (col.id === "marketing") {
                    return <div className="commentCont" style={{ opacity: isSaving ? 0.5 : 1 }}>
                      <Popover
                        className="myPopover"
                        overlayClassName="myPopover"

                        color={getDarkModoe.ToggleDarkModeReducer.chekced ? "#21262E" : "white"}
                        content={
                          <div
                            className="commentCont"
                            style={{
                              width: "100mm", // Пример ширины A4
                              minWidth: "100px",
                              borderRadius: "4px",
                              overflow: "hidden",
                              border: "none",
                            }}
                          >
                            <textarea
                              className="commentOzon"
                              placeholder="Введите ..."
                              value={comments.marketing.value}

                              onChange={(e) => handleChange(e, row, "marketing")}
                              style={textareaStyle}
                            />
                          </div>
                        }
                        title=""
                      >
                        <p>{comments.marketing.value}</p>
                        <p>{
                          comments.marketing.date && comments.marketing.value ?
                            moment(comments.marketing.date).format("ll")
                            :
                            ""
                        }</p>
                      </Popover>



                    </div>
                  } else if (col.id === "quality") {
                    return <div className="commentCont" style={{ opacity: isSaving ? 0.5 : 1 }}>
                      <Popover
                        className="myPopover"
                        overlayClassName="myPopover"

                        color={getDarkModoe.ToggleDarkModeReducer.chekced ? "#21262E" : "white"}
                        content={
                          <div
                            style={{
                              width: "100mm", // Пример ширины A4
                              minWidth: "100px",
                              borderRadius: "4px",
                              overflow: "hidden",
                              border: "none",
                            }}
                          >
                            <textarea
                              className="commentOzon"
                              placeholder="Введите ..."
                              value={comments.quality.value}

                              onChange={(e) => handleChange(e, row, "quality")}
                              style={textareaStyle}
                            />
                          </div>
                        }
                        title=""
                      >
                        <p>{comments.quality.value || ""}</p>
                        <p>{
                          comments.quality.date && comments.quality.value ?
                            moment(comments.quality.date).format("ll")
                            :
                            ""
                        }</p>
                      </Popover>



                    </div>
                  } else if (col.id === "content") {
                    return <div className="commentCont" style={{ opacity: isSaving ? 0.5 : 1 }}>
                      <Popover
                        className="myPopover"
                        overlayClassName="myPopover"

                        color={getDarkModoe.ToggleDarkModeReducer.chekced ? "#21262E" : "white"}
                        content={
                          <div
                            style={{
                              width: "100mm", // Пример ширины A4
                              minWidth: "100px",
                              borderRadius: "4px",
                              overflow: "hidden",
                              border: "none",
                            }}
                          >
                            <textarea
                              className="commentOzon"
                              placeholder="Введите ..."
                              value={comments.content.value}

                              onChange={(e) => handleChange(e, row, "content")}
                              style={textareaStyle}
                            />
                          </div>
                        }
                        title=""
                      >
                        <p>{comments.content.value || ""}</p>
                        <p>{
                          comments.content.date && comments.content.value ?
                            moment(comments.content.date).format("ll")
                            :
                            ""
                        }</p>
                      </Popover>



                    </div>
                  }

                })()
              }
            </td>
          )
        })
      }

    </tr>
  );
};

const DraggableTable = () => {
  const getLisProduct = useSelector(state => state.getOzonisListReducer);
  const getDarkModoe = useSelector(darkModes => darkModes)
  const [isDraggingDisabled, setIsDraggingDisabled] = useState(false);
  const { hasMore, currentPage, weeks } = useSelector((state) => state.getOzonisListReducer.ozon);
  const getMycOMANYapi = useSelector((mycompany) => mycompany.getMyCompanyAPIReducer);
  const columns = getLisProduct.ozon.columns;
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null); // Создаем ссылку на контейнер
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Состояние для первой загрузки
  const touchSensor = useSensor(TouchSensor)
  const isMobile = () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  // Инициализация сенсоров с настройками
  const sensors = useSensors(
    useSensor(MyPointerSensor, {
      activationConstraint: isMobile()
        ? null // Без задержки для настольных устройств
        : {
          delay: 0, // Никакой задержки
          tolerance: 0, // Без смещения
        },
    }),
    useSensor(TouchSensor, {
      activationConstraint: isMobile()
        ? {
          delay: 1000, // Задержка в 500 мс для мобильных устройств
          tolerance: 20, // Допустимое смещение в 10 пикселей
        }
        : null, // Без ограничений для настольных устройств
    })
  );

  useEffect(() => {
    if (getMycOMANYapi.company && getMycOMANYapi.company._id) {
      // Проверяем, есть ли компания и ее ID
      dispatch(updateCompany(getMycOMANYapi.company._id)); // Обновляем состояние текущей компании
    }
  }, [getMycOMANYapi.company._id, dispatch, getCompanyID.checkedSession]);


  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    if (columns.some((col) => col.id === active.id)) {
      const oldIndex = columns.findIndex((col) => col.id === active.id);
      const newIndex = columns.findIndex((col) => col.id === over.id);
      const newColumns = arrayMove(columns, oldIndex, newIndex);
      dispatch(reorderOzonColumns(newColumns));


      // Здесь можно отправить новый порядок колонок на сервер
    } else if (getLisProduct.ozon.data.some((row) => row.id === active.id)) {
      const currentList = getLisProduct.ozon.data;

      const oldIndex = currentList.findIndex((item) => item.id === active.id);
      const newIndex = currentList.findIndex((item) => item.id === over.id);

      const newList = arrayMove(currentList, oldIndex, newIndex);

      // Отправляем новый порядок в Redux
      dispatch(reorderOzonProducts(newList))
      // Здесь можно отправить новый порядок строк на сервер
    }
    // tableRef.current.style.overflow = 'auto';
  };
  const loadMoreData = () => {
    if (hasMore && !loading) {
      setLoading(true);

      notification.open({
        key: 'loadingNotification',
        message: 'Загрузка данных',
        description: 'Загрузка новой страницы данных...',
        duration: 0,
      });

      dispatch(getOzonPorductList('/getozoList/api', currentPage))
        .then(() => {
          setLoading(false);
          setIsInitialLoad(false);

          // Обновление текущей страницы через действие Redux


          notification.open({
            key: 'loadingNotification',
            message: 'Загрузка данных',
            description: 'Данные успешно загружены.',
            duration: 4,
          });
        })
        .catch((error) => {
          console.error("Error loading data:", error);

          notification.open({
            key: 'loadingNotification',
            message: 'Загрузка данных',
            description: 'Ошибка загрузки данных.',
            duration: 4,
          });

          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // if (isInitialLoad) {
    //   loadMoreData();
    //   setIsInitialLoad(false);
    // }
    loadMoreData()
  }, [currentPage, hasMore, dispatch, getCompanyID.checkedSession]);


  const loadMore = () => {
    if (hasMore && !loading) {
      dispatch(updateCurrentPage(currentPage + 1)); // Увеличиваем текущую страницу
    }
  };

  // useEffect(() => {
  //   dispatch(getOzonPorductList('/getozoList/api', currentPage));
  // }, [dispatch, currentPage]);
  const handleScroll = () => {
    if (!tableRef.current) return;
  
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
  
    // Проверяем, если достигнут низ таблицы
    if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
      loadMore();
    }
  };
  
  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        const { clientHeight, scrollHeight } = tableRef.current;
  
        // Если высота таблицы меньше контейнера, добавляем фоновый элемент
        if (clientHeight >= scrollHeight) {
          tableRef.current.style.overflowY = "scroll"; // Принудительная прокрутка
        }
      }
    };
  
    window.addEventListener("resize", handleResize);
    handleResize();
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  console.log(weeks, "weeksweeksweeks");


  if (getLisProduct.error) {
    return (
      <div className='alertError'>
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Ошибка</AlertTitle>
          <AlertDescription>Ключ не добавлен или не работает</AlertDescription>
        </Alert>
      </div>
    );
  }
  if (!getLisProduct.ozon || getLisProduct.ozon.data === null) {
    return (
      <div>
        {
          getLisProduct.loading ?
            <div class="spinner"></div>

            :
            <div>безрезультатно</div>
        }

      </div>
    );
  }



  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      autoScroll={false}

    // sensors={false ? sensors : []}

    >
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "myTableDivDark" : "myTableDiv"} ref={tableRef}
        onScroll={handleScroll}
      >
        <SortableContext items={columns.map((col, index) => col.id)}>
          <table className="draggable-table" >
            <thead>
              <tr className="sticky">
                {columns.map((col, index) => (
                  <DraggableColumn key={col.id} id={col.id} isDraggingDisabled={isDraggingDisabled} setIsDraggingDisabled={setIsDraggingDisabled} index={index} >
                    <p> {col.label}</p>
                  </DraggableColumn>
                ))}
              </tr>
            </thead>
            <tbody>

              <SortableContext items={getLisProduct.ozon.data.map((row) => row.id)}>

                {getLisProduct.ozon.data.map((row, index) => (
                  <DraggableRow key={row.id} id={row.id} row={row} columns={columns} style={{ pointerEvents: 'none' }}
                    isDraggingDisabled={isDraggingDisabled} setIsDraggingDisabled={setIsDraggingDisabled} index={index} />
                ))}
              </SortableContext>
            </tbody>
          </table>
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default DraggableTable;



