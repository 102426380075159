import "./navBarLeftDeskopLight.css";
import "./navBarLeftDeskopDark.css";
import "./navBarLeftDeskopLightMinmize.css";
import "./navBarLeftDeskopDarkMinmize.css";
import logo from "../navBarLeftDeskop/logoForMenu.png";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, AvatarBadge, AvatarGroup, useStatStyles } from '@chakra-ui/react'
import { Switch } from '@chakra-ui/react';
import { toggleMenuAction } from "../../../redux/reducer/toggleMenu/action/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import AddemployeeplusLigth from "../addemployeeplus/addemployeeplusLigth";

import axios from "axios";
import sockets from "../../../socket/socket";
import Banner from "../../page/banner/banner";
import { fetchUsers } from "../../../redux/reducer/postFetcReducer/action/action";

const socket = sockets;
const isDarkstorage = JSON.parse(localStorage.getItem('isDark'));
export default function NavBarLeftDeskop({ handleChangetema }) {
    const toggleMenu = useSelector(toggleMenu => toggleMenu);
    const dispatch = useDispatch();
    const leftMenuRef = useRef();
    const navigate = useNavigate();
    const getDarkModoe = useSelector(darkModes => darkModes);
    const getmyinforeducer = useSelector(compID => compID.getMayInfoReducer);
    const getmyinfo = useSelector(info => info);
    let onlineUSers = useSelector(onlineUSers => onlineUSers);
    const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
    let getmyifno = useSelector(myinfo => myinfo);
    const useData = useSelector(datas => datas)

    const getMyImageReducer = useSelector(getMyImageReducer => getMyImageReducer)
    const [count, setCount] = useState(0)
    const changeToggleMenu = () => {
        dispatch(toggleMenuAction(!toggleMenu.ToggleMenuReducer.toggleMenu));
    }

    useEffect(() => {

        leftMenuRef.current.classList = '';
        if (getDarkModoe.ToggleDarkModeReducer.chekced == false && toggleMenu.ToggleMenuReducer.toggleMenu == true) {


            leftMenuRef.current.classList.add('navBarLeftDeskopContLight');
        } else if (getDarkModoe.ToggleDarkModeReducer.chekced == true && toggleMenu.ToggleMenuReducer.toggleMenu == true) {

            leftMenuRef.current.classList.add('navBarLeftDeskopContDark');
        } else if (getDarkModoe.ToggleDarkModeReducer.chekced == false && toggleMenu.ToggleMenuReducer.toggleMenu == false) {

            leftMenuRef.current.classList.add('navBarLeftDeskopContLightMinimize');
        } else if (getDarkModoe.ToggleDarkModeReducer.chekced == true && toggleMenu.ToggleMenuReducer.toggleMenu == false) {

            leftMenuRef.current.classList.add('navBarLeftDeskopContDarkMinimize');
        }


    }, [getDarkModoe.ToggleDarkModeReducer.chekced, toggleMenu.ToggleMenuReducer.toggleMenu])

    const logoutHandle = () => {
        socket.emit("updateForOnlineUsersStatus")
        axios({
            method: 'get',
            url: '/logout/api',
        })
            .then(function (response) {
                socket.emit('logout', getmyinforeducer.myinfo._id)

                sessionStorage.clear()
                navigate("/login")
                socket.emit('updateMydataOnlineTrueForServer');
                // window.location.replace("/login")

            });
        // return redirect("/login");
    }

    gerChekcedCompany.rootReducer.users.data?.slice().sort((a, b) => {
        const aIndex = onlineUSers.allOnlineUsers?.indexOf(a.workerID);
        const bIndex = onlineUSers.allOnlineUsers?.indexOf(b.workerID);
        if (aIndex === -1 && bIndex === -1) return 0; // If both are offline, maintain original order
        if (aIndex === -1) return 1; // If only "a" is offline, move "a" to the end
        if (bIndex === -1) return -1; // If only "b" is offline, move "b" to the end
        return aIndex - bIndex; // Compare their online indices
    });

    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])

    useEffect(() => {
        axios({
            method: 'get',
            url: '/whoReadThisMessageAddForMainMenu/api',
        })
            .then(function (response) {
                console.log(response.data.data, "response.datasetCountsetCount");
                setCount(response.data.data)
            });
    }, [])


    useEffect(() => {
        function upDateCountMessage() {
            axios({
                method: 'get',
                url: '/whoReadThisMessageAddForMainMenu/api',
            })
                .then(function (response) {
                    console.log(response.data.data, "response.data");
                    setCount(response.data.data)
                });
        }

        if (socket) {
            socket.on('updateCreatMessage', upDateCountMessage);

            return () => {
                socket.off('updateCreatMessage', upDateCountMessage);
            };
        }
    }, []);

    // console.log(gerChekcedCompany.rootReducer.users.data
    //     ,"asdasd");


    const allUsers = gerChekcedCompany.rootReducer.users.data || [];

    // Фильтруем онлайн пользователей
    const onlineUsers = allUsers.filter(item => 
        onlineUSers.getOnlineUserViaRouterReducer?.users.data?.includes(item.workerID || item._id)
    );
    
    // Фильтруем оффлайн пользователей
    const offlineUsers = allUsers.filter(item => 
        !onlineUSers.getOnlineUserViaRouterReducer?.users.data?.includes(item.workerID || item._id)
    );
    
    // Объединяем онлайн и оффлайн пользователей
    const sortedUsers = [...onlineUsers, ...offlineUsers];
    return (
        <div ref={leftMenuRef} className="navBarLeftDeskopContLight">
            <div className="navBarLeftDeskopFirstChild">
                <div className="toggleAndLogoCont">
                    <div className="logoAndSaitNameCont" onClick={() => navigate("/")}>
                        <div >
                            <img src={logo} alt="" />
                        </div>
                        <p>Упрости</p>
                    </div>
                    <div onClick={changeToggleMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.23953 15.3202H16.7595C17.1555 15.3202 17.4795 14.9962 17.4795 14.6002C17.4795 14.2042 17.1555 13.8802 16.7595 13.8802H5.23953C4.84353 13.8802 4.51953 14.2042 4.51953 14.6002C4.51953 14.9962 4.84353 15.3202 5.23953 15.3202ZM5.23953 11.7202H16.7595C17.1555 11.7202 17.4795 11.3962 17.4795 11.0002C17.4795 10.6042 17.1555 10.2802 16.7595 10.2802H5.23953C4.84353 10.2802 4.51953 10.6042 4.51953 11.0002C4.51953 11.3962 4.84353 11.7202 5.23953 11.7202ZM4.51953 7.40018C4.51953 7.79618 4.84353 8.12018 5.23953 8.12018H16.7595C17.1555 8.12018 17.4795 7.79618 17.4795 7.40018C17.4795 7.00418 17.1555 6.68018 16.7595 6.68018H5.23953C4.84353 6.68018 4.51953 7.00418 4.51953 7.40018Z" fill="#585858" />
                        </svg>
                    </div>
                </div>
                <div className="nameAndNavLinkItems">
                    <div className="myInfoAndOptionIcon">
                        <div className="Component38">
                            <div>
                                {getmyinfo.getMayInfoReducer.myinfo.img ?

                                    <Avatar size='sm' src={`/${getmyinfo.getMayInfoReducer.myinfo.img}`} loading="lazy" >
                                        <AvatarBadge boxSize='1em' bg='green.500' />
                                    </Avatar>
                                    :
                                    <Avatar size='sm' src='https://bit.ly/broken-link' loading="lazy" />
                                }


                                {
                                    getmyinfo.getMayInfoReducer.myinfo.name ?
                                        <p>{getmyinfo.getMayInfoReducer.myinfo.name}</p>
                                        :
                                        <p>анонимный</p>
                                }

                            </div>
                            <div>
                                <div onClick={() => navigate("/mydata")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.9998 12.6998C11.491 12.6998 12.6998 11.491 12.6998 9.9998C12.6998 8.50859 11.491 7.2998 9.9998 7.2998C8.50859 7.2998 7.2998 8.50859 7.2998 9.9998C7.2998 11.491 8.50859 12.6998 9.9998 12.6998Z" stroke="#929292" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.8602 8.55586L15.8722 6.17032L17.2 4.6L15.4 2.8L13.8382 4.13466L11.402 3.13277L10.8418 1H9.0829L8.51419 3.16102L6.13397 4.16436L4.6 2.8L2.8 4.6L4.10803 6.20996L3.13525 8.60167L1 9.1V10.9L3.161 11.49L4.16417 13.8697L2.8 15.4L4.6 17.2L6.21204 15.8863L8.5573 16.8511L9.1 19H10.9L11.444 16.8519L13.8296 15.8639C14.2272 16.1482 15.4 17.2 15.4 17.2L17.2 15.4L15.8643 13.8245L16.8525 11.4382L18.9999 10.8795L19 9.1L16.8602 8.55586Z" stroke="#929292" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="leftMenuLinkItem">
                        <NavLink className="Frame54" to={"/task"}>
                            <div className="Frame15">
                                <div className="Frame14">
                                    <div>
                                        <div className="comp32">
                                            <div className="frame4">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                                        <path d="M6.19922 2.40039H17.1992" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 2.2L1.8 2.99999L3.79999 1" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 8.2L1.8 9L3.79999 7" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 14.2L1.8 15L3.79999 13" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.19922 8.40039H17.1992" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.19922 14.4004H17.1992" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <p>Задачи</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink className="Frame54" to={"/chat"}>
                            <div className="Frame15">
                                <div className="Frame14">
                                    <div>
                                        <div className="comp32">
                                            <div className="frame4">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M5 10H15" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M5 6H11" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 18.2895V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V13C19 14.1046 18.1046 15 17 15H5.96125C5.35368 15 4.77906 15.2762 4.39951 15.7506L2.06852 18.6643C1.71421 19.1072 1 18.8567 1 18.2895Z" stroke="#353535" strokeWidth="1.5" />
                                                    </svg>
                                                </div>
                                                <p>Чат</p>
                                                {
                                                    count > 0 && <div className="badgeChatMessage">
                                                        <p>{count}</p>
                                                    </div>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink className="Frame54" to={"/settings/AddNewUser"}>
                            <div className="Frame15">
                                <div className="Frame14">
                                    <div>
                                        <div className="comp32">
                                            <div className="frame4">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                                        <path d="M8.74967 9.16683C10.3605 9.16683 11.6663 7.86099 11.6663 6.25016C11.6663 4.63933 10.3605 3.3335 8.74967 3.3335C7.13884 3.3335 5.83301 4.63933 5.83301 6.25016C5.83301 7.86099 7.13884 9.16683 8.74967 9.16683Z" stroke="#353535" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.62669 14.9998C11.3979 14.9998 12.8901 13.3649 11.6377 12.1125V12.1125C10.8719 11.3467 9.83329 10.9165 8.75033 10.9165C7.66736 10.9165 6.62875 11.3467 5.86297 12.1125V12.1125C4.61057 13.3649 6.1028 14.9998 7.87396 14.9998H9.62669Z" stroke="#353535" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.4163 6.83333C13.9135 6.83333 14.4024 6.70625 14.8367 6.46415C15.2709 6.22205 15.636 5.87296 15.8974 5.45005C16.1588 5.02714 16.3077 4.54443 16.3301 4.04777C16.3524 3.5511 16.2474 3.05697 16.0251 2.61229C15.8027 2.16762 15.4704 1.78715 15.0597 1.50703C14.649 1.22691 14.1734 1.05643 13.6783 1.01179C13.1831 0.967138 12.6848 1.0498 12.2305 1.25193C11.7763 1.45406 11.3813 1.76894 11.083 2.16667" stroke="#353535" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.0003 12.6668H14.2934C16.0645 12.6668 17.5567 11.0319 16.3043 9.77948V9.77948C15.5386 9.0137 14.5 8.5835 13.417 8.5835" stroke="#353535" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.41699 2.16667C6.11869 1.76894 5.72368 1.45406 5.26945 1.25193C4.81523 1.0498 4.31688 0.967138 3.82173 1.01179C3.32657 1.05643 2.85105 1.22691 2.44031 1.50703C2.02957 1.78715 1.69725 2.16762 1.47491 2.61229C1.25257 3.05697 1.14759 3.5511 1.16994 4.04777C1.19229 4.54443 1.34122 5.02714 1.60259 5.45005C1.86397 5.87296 2.22911 6.22205 2.66335 6.46415C3.09758 6.70625 3.58649 6.83333 4.08366 6.83333" stroke="#353535" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4.08333 8.5835C3.00037 8.5835 1.96175 9.0137 1.19598 9.77948V9.77948C-0.0564186 11.0319 1.43581 12.6668 3.20697 12.6668H3.5" stroke="#353535" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <p>Сотрудники</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink className="Frame54" to={"/application"}>
                            <div className="Frame15">
                                <div className="Frame14">
                                    <div>
                                        <div className="comp32">
                                            <div className="frame4">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M10 14.1755L10.01 14.164M5 17.7077V2.29102C5 1.94584 5.26863 1.66602 5.6 1.66602H14.4C14.7314 1.66602 15 1.94584 15 2.29102V17.7077C15 18.0529 14.7314 18.3327 14.4 18.3327H5.6C5.26863 18.3327 5 18.0529 5 17.7077Z" stroke="#353535" stroke-width="1.8" stroke-linecap="round" />
                                                    </svg>
                                                </div>
                                                <p>Приложение</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink className="Frame54" to={"/changeApi"}>
                            <div className="Frame15">
                                <div className="Frame14">
                                    <div>
                                        <div className="comp32">
                                            <div className="frame4">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                                        <path d="M15.0645 15.4844V18.4844M12.0645 15.4844H15.0645H12.0645ZM18.0645 15.4844H15.0645H18.0645ZM15.0645 15.4844V12.4844V15.4844Z" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.48524 7.82838L1 16.3137L3.12132 18.435M9.48524 7.82838C11.0473 9.39048 13.58 9.39048 15.1421 7.82838C16.7042 6.26633 16.7042 3.73367 15.1421 2.17157C13.58 0.609478 11.0473 0.609478 9.48524 2.17157C7.92314 3.73367 7.92314 6.26633 9.48524 7.82838Z" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M3.82812 13.4844L5.94945 15.6057" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <p>API ключи</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                        {/* <NavLink className="Frame54" to={"/settings"}>
                            <div className="Frame15">
                                <div className="Frame14">
                                    <div>
                                        <div className="comp32">
                                            <div className="frame4">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M9.9998 12.6998C11.491 12.6998 12.6998 11.491 12.6998 9.9998C12.6998 8.50859 11.491 7.2998 9.9998 7.2998C8.50859 7.2998 7.2998 8.50859 7.2998 9.9998C7.2998 11.491 8.50859 12.6998 9.9998 12.6998Z" stroke="#353535" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M16.8602 8.55586L15.8722 6.17032L17.2 4.6L15.4 2.8L13.8382 4.13466L11.402 3.13277L10.8418 1H9.0829L8.51419 3.16102L6.13397 4.16436L4.6 2.8L2.8 4.6L4.10803 6.20996L3.13525 8.60167L1 9.1V10.9L3.161 11.49L4.16417 13.8697L2.8 15.4L4.6 17.2L6.21204 15.8863L8.5573 16.8511L9.1 19H10.9L11.444 16.8519L13.8296 15.8639C14.2272 16.1482 15.4 17.2 15.4 17.2L17.2 15.4L15.8643 13.8245L16.8525 11.4382L18.9999 10.8795L19 9.1L16.8602 8.55586Z" stroke="#353535" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <p>Настройки</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink> */}
                        {/* <button onClick={logoutHandle}>Logout</button> */}

                    </div>
                </div>
            </div>
            <div className="navBarLeftDeskopLasttChild">
                <div className="addEmploysAndToggleThemeAndReclamCont">
                    <div>

                        {/* <Banner /> */}
                        <AddemployeeplusLigth />
                    </div>
                    <div className="toggleBTNForTheme">
                        <div className="component38">
                            <div className="frame4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M3 9.56142C3 14.222 6.77808 18 11.4386 18C14.7519 18 17.6192 16.0905 19 13.3119C11.4386 13.3119 7.6881 9.56142 7.6881 2C4.90952 3.38084 3 6.24813 3 9.56142Z" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <p>Темная тема</p>
                            </div>
                            <Switch id='email-alerts' isChecked={getDarkModoe.ToggleDarkModeReducer.chekced} onChange={handleChangetema} />

                        </div>
                    </div>
                </div>
                <div>

                    <AvatarGroup size='md' max={4} >
                        {/* admin */}
                        {
                            getmyifno.rootReducer.users.adminInfo?.online ?
                                <Avatar size={"md"} loading="lazy" src={useData.rootReducer.users.adminInfo?.img ? `/${useData.rootReducer.users.adminInfo.img}` : "https://bit.ly/broken-link"} >
                                    <AvatarBadge boxSize='1.25em' bg='green.500' />
                                </Avatar>
                                :
                                <Avatar size={"md"} loading="lazy" src={useData.rootReducer.users.adminInfo?.img ? `/${useData.rootReducer.users.adminInfo.img}` : "https://bit.ly/broken-link"} />
                        }

                        {/*workers onlione  */}
                        {sortedUsers.map((item, index) => (
                            <Avatar src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} key={index} loading="lazy">
                                {onlineUsers.includes(item) && ( // Проверяем, является ли пользователь онлайн
                                    <AvatarBadge boxSize='1.25em' bg='green.500' />
                                )}
                            </Avatar>
                        ))}
                    </AvatarGroup>
                </div>
            </div>
        </div>
    )
}