import axios from 'axios';

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function register(config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log("Это приложение обслуживается кэшем Service Worker.");
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) return;

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log("Новое обновление доступно.");
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
              showUpdateNotification();
            } else {
              console.log("Контент кэширован для оффлайн-использования.");
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };

      subscribeToPushNotifications(registration);
    })
    .catch((error) => {
      console.error("Ошибка при регистрации Service Worker:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, { headers: { "Service-Worker": "script" } })
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log("Нет соединения с интернетом. Приложение работает в оффлайн-режиме.");
    });
}

export function subscribeToPushNotifications(registration) {
  // Запрашиваем публичный VAPID-ключ с сервера
  axios.get('/vapidPublicKey')
    .then((response) => {
      const vapidPublicKey = response.data.publicKey; // Получаем публичный ключ
      console.log(vapidPublicKey,"vapidPublicKey")
      const applicationServerKey = urlBase64ToUint8Array(vapidPublicKey);

      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Разрешение на уведомления получено.');

          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey,
            })
            .then((subscription) => {
              console.log('Подписка на push уведомления:', subscription);

              // Отправляем подписку на сервер
              sendSubscriptionToServer(subscription);
            })
            .catch((error) => {
              if (error.name === 'NotAllowedError') {
                console.error('Уведомления запрещены пользователем.');
              } else if (error.name === 'InvalidStateError') {
                console.error('PushManager не готов. Убедитесь, что ваш Service Worker активен.');
              } else {
                console.error('Ошибка при подписке на push уведомления:', error);
              }
            });
        } else {
          console.log('Пользователь отклонил разрешение на уведомления');
        }
      });
    })
    .catch((error) => {
      console.error('Ошибка при получении VAPID Public Key:', error);
    });
}

function sendSubscriptionToServer(subscription) {
  fetch('/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(subscription),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Не удалось отправить подписку на сервер.');
      }
      return response.json();
    })
    .then((data) => {
      console.log('Подписка успешно отправлена на сервер:', data);
    })
    .catch((error) => {
      console.error('Ошибка при отправке подписки на сервер:', error);
    });
}

function showUpdateNotification() {
  const notification = document.createElement("div");
  notification.style.position = "fixed";
  notification.style.bottom = "20px";
  notification.style.right = "20px";
  notification.style.background = "#333";
  notification.style.color = "#fff";
  notification.style.padding = "10px 20px";
  notification.style.borderRadius = "5px";
  notification.style.zIndex = "1000";
  notification.innerText = "Доступна новая версия приложения. Обновить?";

  const button = document.createElement("button");
  button.style.marginLeft = "10px";
  button.innerText = "Обновить";
  button.onclick = () => {
    window.location.reload();
  };

  notification.appendChild(button);
  document.body.appendChild(notification);
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error("Ошибка при отмене регистрации Service Worker:", error);
      });
  }
}
