
import { useSelector } from "react-redux"
import WBtable from "./wbtable/wbTable"
import "./wildberries.css"
import "./wildberriesDark.css"


export default function Wildberries() { 
  const getDarkModoe = useSelector(darkModes => darkModes )
            return (
              <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"wbContDark":"wbCont"}>
                < WBtable />     
              </div>
            )
}