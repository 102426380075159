import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import ChilMNodal from "../childModal/childModal";
import Shablon from "../shablon/shablon.jsx";
import Metka from "../metka/metka.jsx";
import Status from "../status/status.jsx";
import SelectMarketPlace from "../selectMarketPlace/selectMarketPlace";
import "./addTask.css"
import "./addTaskDark.css"
import UploadFileTaskMeneger from "../uploadFile/uploadFile";
import Perfomer from "../perfomer/perfomer";
import CommentBoxCreatTask from "../commentCreatTask/commentCreatTask";
import Prioritet from "../prioritet/prioritet";
import { useNavigate } from "react-router";
import LoopingTask from "../loopingTask/loopingTask.jsx";
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action.js";
import sockets from "../../../../socket/socket.js";
import { Avatar, AvatarBadge, AvatarGroup, useToast } from '@chakra-ui/react'
import StartDateModal from "../startDateModal/startDateModal.jsx";
import EndDateModal from "../endDateModal/endDateModal.jsx";
import SubTaskCont from "../subTaskCont/subTaskCont.jsx";
import { getAllTask } from "../../../../redux/reducer/getTaskall/action/action.js";
import { MarketPlaceSelectCreat } from "../marketPlaceSelectCreat/marketPlaceSelectCreat.jsx";
import { getMyCompany } from "../../../../redux/reducer/getCompany/action/action.js";
import { updateOptions } from "../../../../redux/reducer/option/action/action.js";

const socket = sockets;


function CreatTask({ sendMessage, receivedMessages, handleDelet, delLoad, update, handleGetNotify }) {
  const [show, setShow] = useState(false);
  const [child, setChild] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate()

  const handleClose = () => {

    setCurrentTask([])
    setComments([])
    setcommentsVal("")
    setperformer([])
    satTtaskName("")
    setMetka([])
    setTasktaskInfo("")
    setOzon([])
    setBw([])
    setfile([])
    setperfomerID([])
    setchildPerfomer([])
    setChild([])
    setShow(false)

  };
  const handleShow = () => setShow(true);

  const [currentTask, setCurrentTask] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentsVal, setcommentsVal] = useState("");
  const [price, setPrice] = useState(0);
  const [performer, setperformer] = useState([]);
  const [templates, satTemplates] = useState("");
  const [taskName, satTtaskName] = useState("");
  const [templatesArray, satTemplatesArray] = useState([]);
  const currentDate = new Date();
  const nextDayDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  const adjustedDate = new Date(nextDayDate.getTime() - nextDayDate.getTimezoneOffset() * 60000);
  const formattedDate = adjustedDate.toISOString().slice(0, 16);
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const [status, setStatus] = useState("Новая");
  const [prioritet, setPrioritet] = useState("Низший");
  const [metka, setMetka] = useState([]);
  const [taskInfo, setTasktaskInfo] = useState("");
  const [ozon, setOzon] = useState([]);
  const [wb, setBw] = useState([]);
  const [yandex, setYandex] = useState([]);
  const [megaMarket, setmegaMarket] = useState([]);
  const [file, setfile] = useState([]);
  const [Taskfiles, setTaskfiles] = useState([]);
  const [classNameTask, setclassNameTask] = useState("greyClass");
  const subTaskRed = useRef(null);
  const [perfomerID, setperfomerID] = useState([]);
  const [childPerfomer, setchildPerfomer] = useState([])
  const [load, setLoad] = useState(false)
  const [onlineUsers, setOnlineUsers] = useState([]);
  const getDarkModoe = useSelector(darkModes => darkModes);
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer);
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer);
  const getDefaulyCheckedSession = useSelector(company => company.checkedSessionReducer);
  // это состояние для повторяющихся задач
  const [checked, setChecked] = useState(false)
  const [selectDays, setselectDays] = useState("everyDay")
  const startLoopDay = new Date();
  const adjustedDateLoop = new Date(startLoopDay.getTime() - startLoopDay.getTimezoneOffset() * 60000);
  const formattedDateLoop = adjustedDateLoop.toISOString().slice(0, 16);
  const [loopingStartDate, setloopingStartDate] = useState(new Date(formattedDateLoop));
  const [everythreedays, seteverythreedays] = useState(new Date())
  
  // ===============================================================//
  const dispatch = useDispatch()
  let onlineUSers = useSelector(onlineUSers => onlineUSers)
  const toast = useToast()

  useEffect(() => {
    socket.emit('pushmyid', getmyinfo.myinfo._id);
    socket.on('online', (users) => {
      setOnlineUsers(users);
    });

    return () => {
      socket.off('online');
      socket.off('disconnect');
    };
  }, []);

  useEffect(() => {
    dispatch(updateOptions(false, "UPDATE_MY_PROJECT_COMPANY")); // Передаем новое значение
    switch (prioritet) {
      case "Критический":

        setclassNameTask("urgentAndImportant");
        break;
      case "Важно":

        setclassNameTask("LilacClass");
        break;
      case "Срочно":

        setclassNameTask("yellowClass");
        break;
      default:

        setclassNameTask("greyClass");
    }
    setCurrentTask([
      {
        child,
        taskName,
        msg: message,
        comments,
        performer,
        startDate,
        endDate,
        status,
        metka,
        prioritet,
        ozon,
        wb,
        yandex,
        megaMarket,
        classNameTask,
        Taskfiles,
        perfomerID,
        childPerfomer,
        taskInfo,
        price
      },
    ]);


  }, [
    comments,
    performer,
    templates,
    templatesArray,
    startDate,
    endDate,
    status,
    prioritet,
    metka,
    child,
    wb,
    ozon,
    yandex,
    megaMarket,
    taskName,
    file,
    Taskfiles,
    perfomerID,
    childPerfomer,
    taskInfo,
    price
  ]);


  let array = [];

  // добавляем под задачу через Enter
  const setSubStack = (e) => {

    if (e.key === "Enter") {
      if (performer.length === 0) {
        toast({
          position: "top",
          title: 'Ошибка',
          description: "Для  создания подзадач вам необходимо выбрать исполнителя",
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return;
      }
      if (message.length > 0) {
        setChild([
          ...child,
          {
            child: [],
            taskName,
            msg: message,
            comments,
            performer,
            startDate,
            endDate,
            status,
            prioritet,
            Taskfiles,
            taskInfo,
            price
          },
        ]);


        setMessage("");

      }

    }
  };


  // здесь добавляем под задачу
  const setSubStackBTN = (e) => {

    if (performer.length === 0) {
      toast({
        position: "top",
        title: 'Ошибка',
        description: "Для  создания подзадач вам необходимо выбрать исполнителя",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }
    if (message.length > 0) {
      setChild([
        ...child,
        {
          child: [],
          taskName,
          msg: message,
          comments,
          performer,
          startDate,
          endDate,
          status,
          prioritet,
          Taskfiles,
          taskInfo,
          price
        },
      ]);


      setMessage("");

    }

  };


  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const getarraa = (item, index) => {
    if (item.child.length === 0) {
      array = [];
      array.push(child);
      array[0][index].child.push();
    }
  };

  // загрузка файла для задач
  function files(taskID, isChild) {
    const formData = new FormData();

    // Добавляем все файлы в FormData
    for (let i = 0; i < file.length; i++) {
      formData.append('files', file[i]);
    }

    axios.post('/uploadTaskMeneger/api', formData, {
      params: {
        taskID,
        isChild
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // Вы можете обновить интерфейс или отобразить прогресс здесь
        console.log(`Upload progress: ${percentCompleted}%`);
      },
    })
      .then(response => {
        // Обработка ответа если необходимо
        console.log(response.data);
      })
      .catch(error => {
        // Обработка ошибок если есть
        console.error(error);
      });

  }
  // загрузка файлов для подзадач
  const uploadChildImg = async (task, taskID) => {

    for (let i = 0; i < task[0].child.length; i++) {
      const formData = new FormData(); // Create a new FormData object for each child
      for (let j = 0; j < task[0].child[i].file.length; j++) {
        formData.append('files', task[0].child[i].file[j]);
      }
      formData.append('indexChild', i);
      formData.append('task', JSON.stringify(task[0]));
      formData.append('taskID', taskID);

      try {
        const response = await axios.post('uploadTaskMenegerChild/api', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  // для создания файла и прикрепления комментарии
  async function uploadCommentsFiles(taskID) {
    const formData = new FormData();
    try {
      // Добавляем изображения в formData
      comments.forEach((comment, index) => {
        comment.images.forEach((image, imgIndex) => {
          formData.append('files', image, `comment-${index}-image-${imgIndex}`);
        });
      });

      formData.append('comments', JSON.stringify(comments));
      formData.append('taskID', taskID);

      // Отправляем запрос на сервер
      await axios.post(`/addCommentsFiles/api`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("uploadCommentsFiles");
    } catch (error) {
      console.error('Error uploading comments with images:', error);
      throw error;
    }
  }


  async function uploadCommentFileForChild(tasks) {
    const updatedTasks = []; // Инициализируем пустой массив для обновленных задач

    for (const task of tasks) { // Проходим по каждой задаче в массиве задач
      const updatedComments = []; // Инициализируем пустой массив для обновленных комментариев

      for (const comment of task.comments) { // Проходим по каждому комментарию в задаче
        const formData = new FormData(); // Создаем новый объект FormData
        comment.images.forEach((file) => { // Для каждого файла изображения в комментарии
          formData.append('images', file); // Добавляем файл в FormData с именем поля 'images'
        });

        try {
          const response = await axios.post('/uploadChildCommentAndGetForSaveImagePathAndComment/api', formData, { // Отправляем POST-запрос на сервер для загрузки файлов
            headers: {
              'Content-Type': 'multipart/form-data' // Устанавливаем заголовок для отправки форм-данных
            }
          });

          const imagePaths = response.data.paths; // Предполагаем, что сервер возвращает пути к загруженным файлам
          const updatedComment = {
            ...comment, // Сохраняем остальные данные комментария
            images: imagePaths // Обновляем массив изображений с путями к загруженным файлам
          };
          updatedComments.push(updatedComment); // Добавляем обновленный комментарий в массив обновленных комментариев
        } catch (error) {
          console.error('Ошибка при загрузке файлов:', error); // Обрабатываем ошибки при загрузке файлов
        }
      }

      updatedTasks.push({
        ...task, // Сохраняем остальные данные задачи
        comments: updatedComments // Обновляем массив комментариев задачи
      });
    }

    return updatedTasks; // Возвращаем массив обновленных задач
  }





  // отправляем на сервер чтобы создать задачу
  const sendServer = async (task, metka) => {
    if (task[0].performer.length === 0) {
      toast({
        position: "top",
        title: 'Ошибка',
        description: "Для создания задач вам необходимо выбрать исполнителя",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return;
    }
    if (taskName == "") {
      toast({
        position: "top",
        title: 'Ошибка',
        description: "Имя задачи пустое пожалуйста, заполните это поле",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return;
    }
    try {
      setLoad(true);
      setperfomerID([...perfomerID, getmyinfo.myinfo]);

      const response = await axios.post('/createTask/api', {
        CompanyID: getCompanyID.checkedSession,
        message,
        task,
        creatorTask: getmyinfo.myinfo._id,
        perfomerID,
        allmetka: metka,
        isLooping: checked,
        selectDays,
        loopingStartDate,
        everythreedays
      });

      // Загружаем файлы, изображения дочерних элементов и подзадачи
      await Promise.all([
        files(response.data.creatTask._id, false),
        uploadChildImg(task, response.data.creatTask._id),
        uploadCommentFileForChild(task[0].child).then(subTasks => {
          return axios.post('/saveSubTask/api', {
            subTasks,
            taskID: response.data.creatTask._id
          });
        })
      ]);

      // Выполняем загрузку комментариев с изображениями
      await uploadCommentsFiles(response.data.creatTask._id);

      // Отправляем сообщения через сокет после завершения всех операций
      socket.emit('message', { room: getCompanyID.checkedSession, message, task });
      socket.emit('getNotifyEmit');

      // Переходим на другую страницу
      navigate("/task");
    } catch (error) {
      console.error("Ошибка при выполнении:", error);
    } finally {
      setLoad(false);
      setMessage('');
    }
  };



  const HandleSetPerfomerId = (e) => {
    console.log(e);

    setperformer((prevPerformers) => {
      const exists = prevPerformers.some(item => item.workerID === e.workerID);

      if (exists) {
        // Удаляем объект, если он уже существует
        return prevPerformers.filter(item => item.workerID !== e.workerID);
      } else {
        // Добавляем новый объект, если его нет
        return [...prevPerformers, e];
      }
    });

    console.log(performer);
  };


  const HandleDeletePerfomerId = (e) => {
    setperformer([])
    setperfomerID([])
  }

  // здесь удалить подзадачу
  function deleteItemByIndex(index, e) {
    e.stopPropagation();
    setChild(prevArray => {
      const newArray = [...prevArray]; // Create a new array copy
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the modified array
    });
  }

  const handleChangeFile = (e) => {
    setfile(e)
  }


  useEffect(() => {
    dispatch(fetchUsers())
    dispatch(getMyCompany("/mycopany/getallcompany"))
  }, [dispatch, getDefaulyCheckedSession, window.location.href])


  return (

    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CreatTaskDark" : "CreatTask"}>
    <div className="backTask">
    <div  onClick={() => navigate("/task")}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg><p>Назад</p></div>
    </div>
      <div className="headerTask">

        <div>

          <Prioritet setPrioritet={setPrioritet} prioritet={prioritet} />
          <Status setStatus={setStatus} status={status} />
        </div>
        {/* <div>
          <Shablon sendMessage={sendMessage} currentTask={currentTask} perfomerID={perfomerID} receivedMessages={receivedMessages} handleDelet={handleDelet} delLoad={delLoad} update={update} handleGetNotify={handleGetNotify} closeParent={handleClose} />
        </div> */}
      </div>
      <div className="choosePerformerDiv">
        <Perfomer setperfomerID={setperfomerID} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} onlineUsers={onlineUsers} getperformer={onlineUSers.getOnlineUserViaRouterReducer.users.data} performer={performer} setperformer={setperformer} />
      </div>
      <div className="creatTaskButton">
        {
          load ?
            <button colorScheme="blue" disabled>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                </svg>
              </div>
              <p>Загрузка</p>
            </button>
            :
            <button colorScheme="blue" onClick={() => sendServer(currentTask, metka)}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                </svg>
              </div>
              <p>Создать задачу</p>
            </button>
        }

      </div>
      <div className="sectionOtherTask">
        <div>
          <div>
            <label htmlFor="taskNameInput">
              <p>Выберите название задачи </p>
              <input type="text"
                id="taskNameInput"
                placeholder="Введите название задачи"
                className="nameTaskInput"
                value={taskName}
                onChange={(e) => satTtaskName(e.target.value)}

              />
            </label>
          </div>
          <div>
            <label htmlFor="priceCreatInput">
              <p>Цена</p>
              <input type="number"
                id="priceCreatInput"
                placeholder="Цена"
                className="nameTaskInput"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </label>
          </div>

          <div className="dateCreatDiv">
            <div>
              <div>
                <div>
                  <p>Сроки: начало</p>
                  <StartDateModal startDate={startDate} setStartDate={setStartDate} />
                </div>
                <div>
                  <p>Сроки: конец</p>
                  <EndDateModal endDate={endDate} setEndDate={setEndDate} />
                </div>
              </div>
              <LoopingTask setChecked={setChecked} checked={checked} selectDays={selectDays} setselectDays={setselectDays} loopingStartDate={loopingStartDate}
                setloopingStartDate={setloopingStartDate} everythreedays={everythreedays} seteverythreedays={seteverythreedays} creatTaskadminInfo={getmyinfo.myinfo
                } taskName={taskName} />
            </div>
          </div>
          <div>
            <label>
              <p>Добавьте описание задачи</p>
              <textarea name="" id="" placeholder="Добавьте описание задачи" onChange={(e) => setTasktaskInfo(e.target.value)} ></textarea>
            </label>
            <MarketPlaceSelectCreat ozon={ozon} setOzon={setOzon} wb={wb} setBw={setBw} setYandex={setYandex} yandex={yandex} megaMarket={megaMarket} setmegaMarket={setmegaMarket} />
            <UploadFileTaskMeneger file={file} setfile={setfile} handleChangeFile={handleChangeFile} />

          </div>
        </div>
        <div>
          <Metka setMetka={setMetka} metka={metka} />
          <SubTaskCont
            allaray={currentTask}
            sendServer={sendServer}
            setperfomerID={setperfomerID}
            setchildPerfomer={setchildPerfomer}
            handleClose={handleClose}
            deleteItemByIndex={deleteItemByIndex}
            metkas={metka}
            load={load}
            currentTask={currentTask}
            getarraa={getarraa}
            setSubStackBTN={setSubStackBTN}
            message={message}
            setMessage={setMessage}
            setSubStack={setSubStack}
          />
          <CommentBoxCreatTask setComments={setComments} comments={comments} />
        </div>
      </div>
    </div>
  );
}

export default CreatTask;
