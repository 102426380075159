import { useDispatch, useSelector } from "react-redux";
import { updateOptions } from "../../../redux/reducer/option/action/action";
import "./toggleAllMyProjectLight.css";
import "./toggleAllMyProjectDark.css";
import { Switch } from "@chakra-ui/react";

export default function ToggleAllMyProject() {
  // Получаем необходимые состояния из Redux
  const myProject = useSelector((state) => state.optionReducer.myProject);
  const isDarkMode = useSelector((state) => state.ToggleDarkModeReducer.checked); // Имя должно совпадать с вашим состоянием
  
  const dispatch = useDispatch();

  // Обработчик переключения
  const handleToggle = (e) => {
    dispatch(updateOptions(e.target.checked, "UPDATE_MY_PROJECT_COMPANY"));
  };

  return (
    <div className={isDarkMode ? "ToggleAllMyProjectContDark" : "ToggleAllMyProjectContLight"}>
      <div>
        <p>Все проекты</p>
      </div>
      <div>
        <Switch
          size="sm"
          isChecked={myProject} // Установка состояния из Redux
          onChange={handleToggle} // Обновляем состояние при переключении
        />
      </div>
    </div>
  );
}
