import moment from "moment";
import { diff } from 'deep-diff';

const getWBState = {
  wb: {
    data: [],
    weeks: [],
    columns: [],
    isClick: false,
    hasMore: true,
    currentPage: 1,
    totalItems: 0,
    currentCompanyId: null, // Добавлено новое состояние для текущей компании
  },
  loading: false,
  error: null,
};

// Функция для обновления вложенных значений
const getWaldberisListReducer = (state = getWBState, action) => {
  switch (action.type) {
    case "FETCH_WB_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "FETCH_WB_SUCCESS":
      const newData = action.payload.data || []; // Fallback to an empty array if undefined
      const currentData = state.wb.data || []; // Fallback to an empty array if undefined
     

      // Проверка на наличие данных
      if (newData.length === 0 && state.wb.currentPage === 1) {
        return {
          ...state,
          loading: false,
          wb: {
            ...getWBState.wb, // Сброс всех данных
            data: [], // Устанавливаем пустой массив
            currentPage: 1,
          },
        };
      }

      // Получаем различия между текущими и новыми данными
      const differences = diff(currentData, newData);
      const filteredData = state.wb.currentCompanyId
        ? newData.filter(item => item.companiID === state.wb.currentCompanyId) // Фильтрация по текущей компании
        : newData;

      // Обновляем данные, добавляя новые элементы или заменяя существующие
      const updatedData = [...currentData];
      filteredData.forEach((newItem) => {
        const existingIndex = updatedData.findIndex((item) => item.id === newItem.id);
        if (existingIndex > -1) {
          updatedData[existingIndex] = newItem; // Замена целого элемента
        } else {
          updatedData.push(newItem); // Если элемента нет, добавляем его
        }
      });

      // Удаляем дубликаты
      const uniqueData = updatedData.filter((item, index, self) =>
        index === self.findIndex((t) => t.id === item.id)
      );

      // Обновление недель
      const weeks = Array.isArray(action.payload.weeks) ? action.payload.weeks : []; // Fallback to an empty array if undefined
      const updatedWeeks = weeks?.map((week, index) => ({
        ...week,
        id: `id${index + 1}`,
        days: Object.entries(week.days).map(([, day], dayIndex) => ({
          ...day,
          id: `id${dayIndex + 1}id`,
        })),
      }));

      // Генерация столбцов
      const transformedColumns = [
        { id: "name", label: "Артикул продавца" },
        { id: "price", label: "Цена" },
        { id: "stock", label: "Остаток" },
        ...updatedWeeks.map((week, index) => ({
          id: week.id || index,
          label: (
            <>
              {moment(week.startDate).format("L")}
              <br />
              {moment(week.endDate).format("L")}
            </>
          ),
        })),
      ];

      const days = updatedWeeks[7]?.days || [];
      const transformedDays = days.map((day) => ({
        id: day.id,
        label: `${moment(day.date).format("L")}`,
      }));

      // Объединяем старые и новые колонки, исключая дубли
      const previousColumns = state.wb.columns || [];
      const newColumns = [
        ...transformedColumns,
        ...transformedDays,
        { id: "Ads", label: "Заказы в день, шт" },
        { id: "idc", label: "Остаток хватит на дн" },
        { id: "turnover", label: "Оборачиваемость" },
        { id: "strategy", label: "Стратегия" },
        { id: "marketing", label: "Маркетинг" },
        { id: "quality", label: "Качество" },
        { id: "content", label: "Контент" },
      ];

      const mergedColumns = previousColumns.concat(
        newColumns.filter(
          (newCol) => !previousColumns.some((oldCol) => oldCol.id === newCol.id)
        )
      );

      const hasMore = newData.length > 0;

      return {
        ...state,
        loading: false,
        wb: {
          ...state.wb,
          isClick: state.wb.isClick, // Сохраняем значение isClick
          totalItems: action.payload.totalItems,
          hasMore, // Обновляем информацию о том, есть ли еще данные
          currentPage: state.wb.currentPage, // Увеличиваем только при наличии данных
          data: uniqueData, // Обновляем состояние с уникальными данными
          weeks: updatedWeeks,
          columns: mergedColumns,
        },
      };

    case "FETCH_WB_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "UPDATE_WB_COMMENT":
      const findComment = state.wb.data.find(item => item.id === action.payload.id);

      if (findComment) {
        const updatedData = state.wb.data.map(item =>
          item.id === action.payload.id
            ? {
                ...item,
                comments: {
                  ...item.comments,
                  [action.payload.commentType]: {
                    value: action.payload.comments,
                    date: action.payload.date,
                  },
                },
              }
            : item
        );

        return {
          ...state,
          wb: {
            ...state.wb,
            data: updatedData,
          },
        };
      }

      return state; // Если элемент не найден

    case "REORDER_WB_PRODUCTS":
      return {
        ...state,
        wb: {
          ...state.wb,
          data: action.payload,
        },
      };

    case "REORDER_WB_COLUMNS":
      return {
        ...state,
        wb: {
          ...state.wb,
          columns: action.payload, // Сохраняем новый порядок колонок
        },
      };

    case "CLICK_ROWS":
      return {
        ...state,
        wb: {
          ...state.wb,
          isClick: action.payload, // Это должно переключать значение
        },
      };

    case "UPDATE_CURRENT_PAGE":
      return {
        ...state,
        wb: {
          ...state.wb,
          currentPage: action.payload, // Обновление currentPage
        },
      };

    case "SET_CURRENT_COMPANY":
      return {
        ...state,
        wb: {
          ...state.wb,
          currentCompanyId: action.payload, // Устанавливаем текущую компанию
          currentPage:1
        },
      };

    default:
      return state;
  }
};

export default getWaldberisListReducer;
