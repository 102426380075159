import "./ozon.css"
import "./ozonDark.css"
import DableOzon from "../ozoEdit/ozonTable/ozontable"
import { useSelector } from "react-redux"



export default function Ozon() {
  const getDarkModoe = useSelector(darkModes => darkModes )
 
            return (
                <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"ozonParentContDark":"ozonParentCont"}>  
                < DableOzon />
              </div>
            )
}