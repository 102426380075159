const getAllTaskInitialState = {
  users: [],
  loading: false,
  error: null,
  currentPage: 1,
  itemsPerPage: 10, // можно задать значение по умолчанию
  totalPages: 0
};

const getAllTaskReducer = (state = getAllTaskInitialState, action) => {
  switch (action.type) {
    case 'FETCH_GETALLTASK_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'FETCH_GETALLTASK_SUCCESS':
      return {
        ...state,
        loading: false,
        users: action.payload.users, // массив данных
        currentPage: action.payload.currentPage, // текущая страница
        totalPages: action.payload.totalPages, // общее количество страниц
        itemsPerPage: action.payload.itemsPerPage || state.itemsPerPage // количество элементов на странице
      };
    case 'FETCH_GETALLTASK_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };

      case 'SET_PAHE_TABLE':
        return {
          ...state,
          currentPage: action.payload.currentPage // обновляем текущую страницу
        };
    default:
      return state;
  }
};

export default getAllTaskReducer;
