import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react'

import { Checkbox } from "@chakra-ui/react";
import Spinner from "react-bootstrap/Spinner";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  Avatar, AvatarBadge, AvatarGroup
} from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'


import { useSelector, useDispatch } from "react-redux";
import { getCaompanyAllAction } from "../../../../../redux/reducer/getAllCompanyForAdmin/action/action";
import "./modalEditCompany.css";
import BlockCompany from "../blockCompany/blockCompany";
export default function ModalEdit({ shows, setShows, data, currentPage, index }) {
  const [show, setShow] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companyAdminInfo, setcompanyAdminInfo] = useState({});
  const [getTask, setgetTask] = useState([]);

  const handleClose = () => setShows(false);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast()
  useEffect(() => {
    console.log(data, "data");

    setShow(shows);
  }, [shows, setShows]);

  const editCompantByAdmin = (e) => {
    e.preventDefault();

    setLoading(true)
    axios
      .patch("/copany/edit/byAdminPanel", {
        data: {
          inn: e.target.inn.value,
          sellersname: e.target.sellersname.value,
          indirecttax: e.target.indirecttax.checked,
          Legaladdress: e.target.Legaladdress.value,
          Checkingaccount: e.target.Checkingaccount.value,
          btc: e.target.btc.value,
          ognip: e.target.ognip.value,
          companyID: data._id,
          walberisToken: e.target.walberisToken.value,
          walberisToken2: e.target.walberisToken2.value,
          walberisToken3: e.target.walberisToken3.value,
          ozonToken1: e.target.ozonToken1.value,
          ozonToken2: e.target.ozonToken2.value,
          BlockCompanys: e.target.blockCompany.checked,
        },
      })
      .then((rezult) => {
        dispatch(getCaompanyAllAction(`/getAllCompant/admin/api?page=${currentPage}`))
        setLoading(false)
        setShows(false)
      }).catch(error => {
        console.log(error, "asdasd");
        setLoading(false)
        toast({
          position: "top",
          title: 'Account created.',
          description: error.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  };


  useEffect(() => {
    console.log(data.ownerofcompanyID);
    axios.get('/getOwnerofcompanyID/api', {
      params: {
        ownerofcompanyID: data.ownerofcompanyID,
        companyID: data._id
      }
    })
      .then((response) => {

        setcompanyAdminInfo(response.data.companyAdminInfo)
        setgetTask(response.data.getTask)


      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [])

  return (
    <>




      <Button onClick={onOpen}>{data?.sellersname}</Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Редактирование компании</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="modalEditCompany">
              <Accordion allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as='span' flex='1' textAlign='left'>
                        информация о владельце компании
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {
                      companyAdminInfo.online ?
                        <Avatar src={`/${companyAdminInfo.img ? companyAdminInfo.img : "https://bit.ly/broken-link"}`} >
                          <AvatarBadge boxSize='1.25em' bg='green.500' />
                        </Avatar>
                        :
                        <Avatar name='Dan Abrahmov' src={`/${companyAdminInfo.img ? companyAdminInfo.img : "https://bit.ly/broken-link"}`} />
                    }


                    <FormControl>
                      <FormLabel>Имя</FormLabel>
                      <Input placeholder='Имя' value={companyAdminInfo.name} />
                      <FormLabel>Тел.</FormLabel>
                      <Input placeholder='Тел.' value={companyAdminInfo.tel} />
                      <FormLabel>email</FormLabel>
                      <Input placeholder='email' value={companyAdminInfo.email} />
                      <FormLabel>telegram</FormLabel>
                      <Input placeholder='telegram' value={companyAdminInfo.telegram} />
                      <FormLabel>whatsUp</FormLabel>
                      <Input placeholder='whatsUp' value={companyAdminInfo.whatsUp} />
                    </FormControl>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as='span' flex='1' textAlign='left'>
                        задача принадлежит этой компании 
                        {
                          getTask?.length> 0?
                          <p className="bridge">{getTask?.length}</p>
                          :
                          ""
                        }
                        
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>

                    <div style={{ height: "50dvh", overflow: "auto" }}>
                      {
                        getTask?.map((item, indx) => {
                            console.log(item)
                          return (
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box as='span' flex='1' textAlign='left'>
                                    {item.alltask[0].taskName}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <div key={indx} style={{ padding: '10px', border: '1px solid #ddd', marginBottom: '10px', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                                  <h2>Задача #{indx + 1}</h2>
                                  <p><strong>ID:</strong> {item._id}</p>
                                  <p><strong>Order:</strong> {item.order}</p>
                                  <p><strong>Mail:</strong> {item.mail}</p>
                                  <p><strong>Company ID:</strong> {item.companyID}</p>
                                  <p><strong>Text:</strong> {item.text}</p>
                                  <p><strong>Date:</strong> {new Date(item.date).toLocaleString()}</p>
                                  <p><strong>ID постановщика   :</strong>
                                    {
                                      item.creatorTasks
                                    }
                                  </p>
         
                                  
                                  

                                  <h3>Исполнители</h3>
                                  {item?.alltask[0].performer?.map((performer, index) => (
                                    <div key={index} style={{ marginLeft: '20px', padding: '10px', backgroundColor: '#e8f4f8', borderRadius: '5px' }}>
                                      <p><strong>Worker ID:</strong> {performer.workerID}</p>
                                      <p><strong>Роль:</strong> {performer.rols}</p>
                                      <p><strong>Имя:</strong> {performer.name}</p>
                                      <p><strong>Email:</strong> {performer.email || 'N/A'}</p>
                                      <p><strong>Телефон:</strong> {performer.tel}</p>
                                      <p><strong>Изображение:</strong>
                                        <br />
                                        <Avatar size='sm' name={performer.tel} src={`/${performer.img}`} />{' '}

                                      </p>
                                    </div>
                                  ))}

                                  <h3>Задачи</h3>
                                  {item?.alltask?.map((task, index) => (


                                    <div key={index} style={{ marginLeft: '20px', padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                                      <p><strong>Название задачи:</strong> {task.taskName}</p>
                                      <p><strong>Статус:</strong> {task.status}</p>
                                      <p><strong>Дата начала:</strong> {new Date(task.startDate).toLocaleString()}/UTC:{task.startDate}</p>
                                      <p><strong>Дата окончания:</strong> {new Date(task.endDate).toLocaleString()}/UTC:{task.endDate}</p>
                                      <p><strong>Приоритет:</strong> {task.prioritet}</p>

                                      <h4>Исполнители задачи:</h4>
                                      {task.performer?.map((p, index) => (
                                        <div key={index} style={{ marginLeft: '20px', padding: '10px', backgroundColor: '#e8f4f8', borderRadius: '5px' }}>
                                          <p><strong>Имя:</strong> {p.name}</p>
                                          <p><strong>Email:</strong> {p.email}</p>
                                          <p><strong>Телефон:</strong> {p.tel}</p>
                                          <p><strong>Изображение:</strong>
                                            <br />
                                            <Avatar size='sm' name={p.tel} src={`/${p.img}`} />{' '}
                                          </p>
                                        </div>
                                      ))}

                                      <h4>Подзадачи:</h4>
                                      {task.child && task.child.length > 0 ? (
                                        task.child.map((subtask) => (
                                          <AccordionItem>
                                            <h2>
                                              <AccordionButton>
                                                <Box as='span' flex='1' textAlign='left'>
                                                {subtask.msg}
                                                </Box>
                                                <AccordionIcon />
                                              </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                              <div key={subtask._id} style={{ marginLeft: '20px', padding: '10px', backgroundColor: '#f7f7f7', borderRadius: '5px' }}>
                                                <p><strong>Название подзадачи:</strong> {subtask.taskName}</p>
                                                <p><strong>Сообщение:</strong> {subtask.msg}</p>
                                                <p><strong>Статус:</strong> {subtask.status}</p>
                                                <p><strong>Приоритет:</strong> {subtask.prioritet}</p>
                                                <p><strong>Дата начала:</strong> {new Date(subtask.startDate).toLocaleString()}/UTC:{subtask.startDate}</p>
                                                <p><strong>Дата окончания:</strong> {new Date(subtask.endDate).toLocaleString()}/UTC:{subtask.endDate}</p>
                                                {subtask.performer?.map((p, index) => (
                                                  <div key={index} style={{ marginLeft: '20px', padding: '10px', backgroundColor: '#e8f4f8', borderRadius: '5px' }}>
                                                    <p><strong>Имя:</strong> {p.name}</p>
                                                    <p><strong>Email:</strong> {p.email}</p>
                                                    <p><strong>Телефон:</strong> {p.tel}</p>
                                                    <p><strong>Изображение:</strong>
                                                      <br />
                                                      <Avatar size='sm' name={p.tel} src={`/${p.img}`} />{' '}
                                                    </p>
                                                  </div>
                                                ))}
                                              </div>
                                            </AccordionPanel>
                                          </AccordionItem>

                                        ))
                                      ) : (
                                        <p>Нет подзадач</p>
                                      )}

                                    </div>
                                  ))}
                                </div>
                              </AccordionPanel>
                            </AccordionItem>

                          );
                        })
                      }
                    </div>

                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <form onSubmit={editCompantByAdmin}>
                <div className="formchildCont1">
                  <label htmlFor="">ИНН</label>
                  <Input
                    placeholder="ИНН"
                    name="inn"
                    defaultValue={data?.inn}
                    key={data?.inn + "1"}
                  />
                  <label htmlFor="">Наименование продавца</label>
                  <Input
                    placeholder="наименование продавца"
                    name="sellersname"
                    defaultValue={data?.sellersname}
                    key={data?.sellersname + "2"}
                  />
                  <div className="chekcbox" color="#5E80E4">
                    {" "}
                    <Checkbox
                      name="indirecttax"
                      defaultChecked={data?.indirecttax}
                    >
                      С НДС
                    </Checkbox>
                  </div>
                  <label htmlFor="">Юридический адрес</label>
                  <Input
                    as="textarea"
                    rows={4}
                    placeholder="Юридический адрес"
                    name="Legaladdress"
                    defaultValue={data?.Legaladdress}
                    key={data?.Legaladdress + "3"}
                  />

                  <div className="apis">
                    {/* api inputs ============================================================================== */}
                    <Accordion allowMultiple>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              Wb API
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="wbCont"  >
                          <label htmlFor="">Общий</label>
                          <Input
                            placeholder="Общий"
                            name="walberisToken"
                            defaultValue={data?.walberisToken}
                            key={data?.walberisToken + "4"}
                          />

                          <label htmlFor="">Статистика</label>
                          <Input placeholder="Статистика" name="walberisToken2" />
                          <label htmlFor="">Реклама</label>
                          <Input placeholder="Реклама" name="walberisToken3" />
                        </AccordionPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              Ozon API
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <label htmlFor="">Администратор</label>
                          <Input placeholder="Администратор" name="ozonToken1" />
                          <label htmlFor="">Товары</label>
                          <Input placeholder="Товары" name="ozonToken2" />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    {/* ============================================================================================================== */}
                  </div>

                </div>

                <div className="formchildCont1">
                  <label htmlFor="">Расчётный счёт</label>
                  <Input
                    placeholder="Расчётный счёт"
                    name="Checkingaccount"
                    defaultValue={data?.Checkingaccount}
                    key={data?.Checkingaccount + "5"}
                  />

                  <label htmlFor="">БИК</label>
                  <Input
                    placeholder="БИК"
                    name="btc"
                    defaultValue={data?.btc}
                    key={data?.btc + "6"}
                  />
                  <label htmlFor="">ОГРН (ОГРНИП)</label>
                  <Input
                    placeholder="ОГРНИП"
                    name="ognip"
                    defaultValue={data?.ognip}
                    key={data?.ognip + "7"}
                  />
                  <BlockCompany data={data?.isBlocedCompany} key={index} currentPage={currentPage} />
                  <div className={"loadingbtn"}>
                    {loading ? (
                      <Button
                        variant="primary"
                        disabled
                        style={{ backgroundColor: "#5E83E0" }}
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        загрузка...
                      </Button>
                    ) : (
                      <Button
                        colorScheme="blue"
                        type="submit"

                      >
                        Редактировать
                      </Button>
                    )}
                  </div>
                </div>

              </form>
              {/* <button>Редактировать данные продавца</button> */}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Закрывать
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
