import axios from "axios";
import sockets from "../../../../socket/socket"; // Убедитесь, что этот путь правильный

export const getWaldberisListAction = (url, page) => {

  return (dispatch) => {
    dispatch({ type: "FETCH_WB_REQUEST" });

    // Получаем данные через Axios
    return axios // Вернем Promise от axios
      .get(`${url}?page=${page}`)
      .then((response) => {
        console.log(response.data.data,"actionn")
        dispatch({
          type: "FETCH_WB_SUCCESS",
          payload: {
            data: response.data.data, // Предполагается, что в ответе есть поле products
            weeks: response.data.weeks, // Предполагается, что в ответе есть поле weeks
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_WB_FAILURE",
          payload: error.message,
        });
        throw error; // Пробрасываем ошибку для обработки в loadMoreData
      });
  };
};


export function handleCommentUpdate(id, value, date, commentType) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_WB_COMMENT",
      payload: {
        id,
        value, // Отправляем значение комментария
        date,  // Отправляем дату
        commentType, // Передаем тип комментария
      },
    });
  };
}


export function reorderWBProducts(newOrder) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_WB_PRODUCTS",
      payload: newOrder,
    });
  };
}

export const reorderWBColumns = (columns) => ({
  type: "REORDER_WB_COLUMNS",
  payload: columns,
});


export const handleClickRow = (val) => ({
  type: "CLICK_ROWS",
  payload: val,
});


export const updateCurrentPage = (page) => ({
  type: "UPDATE_CURRENT_PAGE",
  payload: page,
});



export const updateCompany = (companyID) => ({
  type: "SET_CURRENT_COMPANY",
  payload: companyID,
});




