// import React, { useState, useEffect, useCallback } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Helmet } from 'react-helmet';
// import axios from 'axios';

// import "./taskmeneger.css";
// import "./taskmenegerDark.css";

// import TaskTable from './tableTask/tableTask';
// import { getAllTask } from '../../../redux/reducer/getTaskall/action/action';
// import sockets from '../../../socket/socket';

// const socket = sockets;

// export default function TaskMeneger(params) {
//   const [message, setMessage] = useState('');
//   const [receivedMessages, setReceivedMessages] = useState([]);
//   const [delLoad, setDelload] = useState(false);
//   const [load, setLoad] = useState(false);

//   const getDarkModoe = useSelector(darkModes => darkModes);
//   const dispatch = useDispatch();
//   let getCompanyID = useSelector(compID => compID.checkedSessionReducer);
//   let getmyinfo = useSelector(compID => compID.getMayInfoReducer);
//   const getAllTaskRed = useSelector(compID => compID.getAllTaskReducer);
//   let onlineUSers = useSelector(compID => compID.AllOnlineUsersReducer);
//   const filters = useSelector(state => state.optionReducer.filters);
//   const taskFiltersObject = useSelector(state => state.optionReducer.taskFiltersObject);

//   // Обработчики сокета вне useEffect
//   const updateTasks = useCallback(() => {
//     if (filters === false) {
//       dispatch(getAllTask("/getTaskfinded/api"));
//     }else{
//       const params = new URLSearchParams({ filters: JSON.stringify(taskFiltersObject) }).toString();
//       dispatch(getAllTask(`/getTaskfindedByFilter/api?${params}`));
//     }

//   }, [dispatch]);

//   const handleNewMessage = useCallback(() => {
//     if (filters === false) {
//       updateTasks();
//     }else{
//       const params = new URLSearchParams({ filters: JSON.stringify(taskFiltersObject) }).toString();
//       dispatch(getAllTask(`/getTaskfindedByFilter/api?${params}`));
//     }

//   }, [updateTasks]);

//   const handleGetAllTaskANDupdate = useCallback(() => {
//     updateTasks();
//   }, [updateTasks]);

//   const handleDataUpdated = useCallback(() => {
//     update();
//   }, []);

//   useEffect(() => {
//     socket.on('newMessage', handleNewMessage);
//     socket.on('getAllTaskANDupdate', handleGetAllTaskANDupdate);
//     socket.on('data-updated', handleDataUpdated);

//     return () => {
//       socket.off('newMessage', handleNewMessage);
//       socket.off('getAllTaskANDupdate', handleGetAllTaskANDupdate);
//       socket.off('data-updated', handleDataUpdated);
//     };
//   }, []);


//   useEffect(() => {
//     // Запрос для получения всех задач при инициализации
//     dispatch(getAllTask("/getTaskfinded/api"));
//   }, [dispatch]);

//   useEffect(() => {
//     // Эмитируем событие для получения задач на основе сессии компании
//     if (getCompanyID.checkedSession) {
//       socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
//     }

//     // Очистка сообщений при изменении сессии
//     setReceivedMessages([]);
//   }, [getCompanyID.checkedSession]);

//   const sendMessage = (task, perfomerID, metka) => {
//     perfomerID.push(getmyinfo.myinfo);
//     axios.post('/createTask/api', {
//       CompanyID: getCompanyID.checkedSession,
//       message,
//       task,
//       creatorTask: getmyinfo.myinfo._id,
//       perfomerID,
//       allmetka: metka
//     })
//       .then(() => {
//         socket.emit('message', { room: getCompanyID.checkedSession, message, task });
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     setMessage('');
//   };

//   const update = () => {
//     socket.emit('message', { room: getCompanyID.checkedSession });
//   };

//   const handleDelet = (card) => {
//     setDelload(true);
//     axios.post('/taskmeneger/delete/api', {
//       companyID: card.companyID,
//       cardID: card._id
//     })
//       .then(() => {
//         socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
//         socket.emit('getNotifyEmit');
//         socket.emit('message', { room: getCompanyID.checkedSession });
//         setDelload(false);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const handleGetNotify = () => {
//     socket.emit('getNotifyEmit');
//   };



//   return (
//     <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "taskMenegerContDark" : "taskMenegerContLight"}>
//       <Helmet>
//         <title>Трекер задач</title>
//         <meta name="description" content="Luchniy Tracker задач - надежное решение для управления задачами вашего бизнеса. Наш Tracker задач предлагает интуитивно понятный интерфейс и мощные инструменты для эффективного планирования, отслеживания и управления задачами. С нашим Tracker задач вы можете легко организовывать работу своей команды, устанавливать сроки выполнения задач и отслеживать их выполнение в реальном времени. Наш Tracker задач обеспечивает полную прозрачность и контроль над процессом выполнения задач, что помогает оптимизировать производительность и достичь поставленных целей. Благодаря гибким настройкам и интеграциям, наш Tracker задач легко адаптируется под потребности вашего бизнеса. Надежный, масштабируемый и безопасный - наш Tracker задач является незаменимым инструментом для успешного ведения вашего бизнеса. Не откладывайте управление задачами на потом - начните использовать наш Tracker задач уже сегодня и повысьте эффективность работы вашей команды!" />
//       </Helmet>

//       <TaskTable receivedMessages={receivedMessages} handleDelet={handleDelet} delLoad={delLoad} update={update} load={load} handleGetNotify={handleGetNotify} onlineUsers={onlineUSers.allOnlineUsers} sendMessage={sendMessage} />
//     </div>
//   );
// }

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import "./taskmeneger.css";
import "./taskmenegerDark.css";

import TaskTable from './tableTask/tableTask';
import { getAllTask, setPageAction } from '../../../redux/reducer/getTaskall/action/action';
import sockets from '../../../socket/socket';
import { Pagination } from '@nextui-org/react';

const socket = sockets;

export default function TaskMeneger() {
  const [message, setMessage] = useState('');
  const [delLoad, setDelload] = useState(false);
  const [load, setLoad] = useState(false);

  const getDarkMode = useSelector(state => state.ToggleDarkModeReducer.chekced);
  const dispatch = useDispatch();
  const getCompanyID = useSelector(state => state.checkedSessionReducer);
  const getMyInfo = useSelector(state => state.getMayInfoReducer);
  const filters = useSelector(state => state.optionReducer.filters);
  const taskFiltersObject = useSelector(state => state.optionReducer.taskFiltersObject);
  const onlineUsers = useSelector(state => state.AllOnlineUsersReducer.allOnlineUsers);
  const myProject = useSelector((state) => state.optionReducer.myProject);
  const getAllTaskRed = useSelector(compID => compID.getAllTaskReducer);

  
  // Общая функция для обновления задач
  const fetchTasks = useCallback(() => {
    let url;
    if (!filters && myProject) {
      url = `/getFindedTaskForAllProject/api?page=${getAllTaskRed.currentPage}&limit=${getAllTaskRed.itemsPerPage}`;
    } 
    const params = new URLSearchParams({
      filters: JSON.stringify(taskFiltersObject),
      page:getAllTaskRed.currentPage,  // Добавляем текущую страницу
      limit: getAllTaskRed.itemsPerPage // Добавляем количество элементов на странице
    }).toString()
    if (filters) {
      url = `/getTaskfindedByFilter/api?${params}`;
    } 
   
    if (!filters && !myProject) {
      url = `/getTaskfinded/api?page=${getAllTaskRed.currentPage}&limit=${getAllTaskRed.itemsPerPage}`;
    }
  
    dispatch(getAllTask(url));
  }, [dispatch, filters, taskFiltersObject, myProject,getAllTaskRed.currentPage]);
  

  useEffect(() => {
    // Обработчики событий сокета
    const eventHandlers = {
      newMessage: fetchTasks,
      getAllTaskANDupdate: fetchTasks,
      'data-updated': fetchTasks,
    };

    // Регистрация обработчиков
    Object.entries(eventHandlers).forEach(([event, handler]) => {
      socket.on(event, handler);
    });

    // Очистка обработчиков при размонтировании
    return () => {
      Object.keys(eventHandlers).forEach(event => {
        socket.off(event, eventHandlers[event]);
      });
    };
  }, [fetchTasks]);

  useEffect(() => {
    // Инициализация задач при загрузке
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    // Эмитируем событие при смене компании
    if (getCompanyID.checkedSession) {
      socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
    }
  }, [getCompanyID.checkedSession]);

  const sendMessage = (task, performerIDs, tags) => {
    const updatedPerformers = [...performerIDs, getMyInfo.myinfo];
    axios.post('/createTask/api', {
      CompanyID: getCompanyID.checkedSession,
      message,
      task,
      creatorTask: getMyInfo.myinfo._id,
      performerIDs: updatedPerformers,
      allTags: tags,
    })
      .then(() => {
        socket.emit('message', { room: getCompanyID.checkedSession, message, task });
      })
      .catch(console.error);

    setMessage('');
  };

  const handleDelete = (task) => {
    setDelload(true);
    axios.post('/taskmeneger/delete/api', {
      companyID: task.companyID,
      cardID: task._id,
    })
      .then(() => {
        socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
        fetchTasks();
        setDelload(false);
      })
      .catch(console.error);
  };

  const handleGetNotify = () => socket.emit('getNotifyEmit');
  
  const handlePageChange = (page) => {
    dispatch(setPageAction(page))
    let url;
    if (!filters && myProject) {
      url = `/getFindedTaskForAllProject/api?page=${page}&limit=${getAllTaskRed.itemsPerPage}`;
    } 
    const params = new URLSearchParams({
      filters: JSON.stringify(taskFiltersObject),
      page,  // Добавляем текущую страницу
      limit: getAllTaskRed.itemsPerPage // Добавляем количество элементов на странице
    }).toString()
    if (filters) {
      url = `/getTaskfindedByFilter/api?${params}`;
    } 
   
    if (!filters && !myProject) {
      url = `/getTaskfinded/api?page=${page}&limit=${getAllTaskRed.itemsPerPage}`;
    }
  
    dispatch(getAllTask(url));
  };



  return (
    <div className={getDarkMode ? "taskMenegerContDark" : "taskMenegerContLight"}>
      <Helmet>
        <title> Задачи — Управление и контроль в Упрости.рф</title>
        <meta name="description" content="Управляйте задачами эффективно с Упрости.рф. Создавайте, редактируйте и отслеживайте выполнение задач для маркетплейсов в удобном таск-трекере. Попробуйте бесплатно!" />
      </Helmet>

      <TaskTable
        handleDelete={handleDelete}
        delLoad={delLoad}
        update={fetchTasks}
        load={load}
        handleGetNotify={handleGetNotify}
        onlineUsers={onlineUsers}
        sendMessage={sendMessage}
      />
      <Pagination        
        total={getAllTaskRed.totalPages}
        initialPage={getAllTaskRed.currentPage}
        onChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}
